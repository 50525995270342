<template>
  <div class="pos-r phones flex aic jcc wfull mt2" >
      <img ref="ph_3" class="sh-m phone zi2 pos-a" :src="first" alt="">
      <img ref="ph_1" class="sh-m phone zi3" :src="second" alt="">
      <img ref="ph_2" class="sh-m phone zi2 pos-a" :src="third" alt="">
  </div>
</template>

<script>
import { TimelineLite, Back } from 'gsap'



export default {
    props: ['runAnim', 'imgs'],

    mounted() {
        if(this.runAnim) this.animation()
    },

    watch: {
        runAnim: {
            handler(val) {
                if(val) this.animation()
            }
        }
    },

    computed: {
        first() { return require(`@/assets/img/${this.imgs[0]}-phone.png`) },
        second() { return require(`@/assets/img/${this.imgs[1]}-phone.png`) },
        third() { return require(`@/assets/img/${this.imgs[2]}-phone.png`) },
    },

    methods: {
        animation() {
            const { ph_1, ph_2, ph_3 } = this.$refs
            const timeline = new TimelineLite()
            const timeline2 = new TimelineLite()


            timeline2.to(ph_1, 1.5, {
                opacity: 1,
                y: 0,
                ease: Back.easeInOut, // Specify an ease
            })

            timeline.to([ph_2, ph_3], 1.5, {
                opacity: 1,
                y: 0,
                delay: 0.8,
                ease: Back.easeInOut, // Specify an ease
            })
            
            
        }
    }
}
</script>

<style lang="scss">
    .phones {
        .phone {
            flex-basis: auto !important;
            width: 53.334% !important;

            &:nth-child(1) {
                top: -2rem;
                left: -2.3rem;
            }

            &:nth-child(2) {
                opacity: 0;
                transform: translateY(100px);
            }

            &:nth-child(1), &:nth-child(3) {
                opacity: 0;
                transform: translateY(-100px);
            }

            &:nth-child(3) {
                top: -2rem;
                right: -2.3rem;
            }

            @media ( max-width: 992px ) {
                &:nth-child(1) {
                    left: -3.5rem;
                }

                &:nth-child(3) {
                    right: -3.5rem;
                }
            }

            @media ( max-width: 768px ) { 
                &:nth-child(1) {
                    left: 0;
                }

                &:nth-child(3) {
                    right: 0;
                }
            }

            @media ( max-width: 576px ) and ( min-width: 480px ) {
                &:nth-child(1) {
                    left: -2rem !important;
                }

                &:nth-child(3) {
                    right: -2rem !important;
                }
            }
        }
    }
</style>