<template>
   <section class="main" id="main">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-12" :class="{mb2: isMobile}">
                    <h2 class="font-r t500 fs75 m0 mb4 tblack mt3 lh89p mobile-text20">{{ $t("main title") }}</h2>
                    <p class="fs24 t400 font-r m0 mb9 tblack mobile-text16">{{ $t("main subtitle") }}</p>
                </div>

                <div class="col-md-6 col-12 flex jcc">
                    <lottie-animation
                        path="./animation.json"
                        :loop="true"
                        v-if="!isMobile"
                        :autoPlay="true"
                        :speed="1"
                        :width="300"
                        :height="300"
                        class="mtminus5"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="text" ref="text">
                        <button class="pt1 pb1 pl3 pr3 br4 bn fs10 t700 fupper twhite sh1 w150 mb2 mobile-label bglg-g">About Project</button>
                        <p class="tblack fs16 lh4 t400 font-o descr m0 mb5"> {{ $t("main descr") }}</p>

                        <div class="flex flexcol social">
                            <h4 class="fs12 t700 m0 mb1 fupper font-o">Follow Us</h4>
                            <div class="flex socials flexw">
                                <Social 
                                    v-for="(item, id) in socials"
                                    :class="{ml1: id > 0}"
                                    :key="id"
                                    :np="true"
                                    :social="item.label"
                                    :description="item.description"
                                    :link="item.link"
                                    :labelSoc="isMobile ? item.mobile : false"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6 flex jcfe pos-r">
                    <div class="w65 p34 twhite br8 flex flexcol aic jcc banner-kick pos-a">
                        <h3 class="fs25 t300 font-o m0 mb2 fupper">{{ $t("main title banner") }}</h3>
                        <Kick/>
                        <a href="https://announce.cerviniaresort.com/"><button class="pt15 pb15 pl5 pr5 mt2 brf bn bglg-o fs10 t700 fupper twhite sh1">More about this project</button></a>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
import { TimelineLite } from 'gsap'
import Kick from '@/components/Kick.vue'
import Social from '@/components/Social'

export default {
    components: { Kick, Social },

    props: ['isMobile'],
    
    data() {
        return {
            socials: [
                {label: 'telegram', description: 'Cervinia_Resort', mobile: 'Join Group', link: 'https://t.me/Cervinia_Resort'},
                {label: 'instagram', description: 'Matterhorn Ski Resorts', mobile: 'Follow Us', link: 'https://www.instagram.com/cerviniaresort/'},
                {label: 'facebook', description: 'Matterhorn Ski Resorts', mobile: 'Follow Us', link: 'https://www.facebook.com/CerviniaResort'},                
            ],
        }
    },

    mounted() {

    },

    computed: {
        anim() {
            const anim = require('@/assets/img/animation.json')

            return JSON.stringify(anim)
        }
    },

    methods: {
        
    }

}
</script>

<style lang="scss">
.main {
    padding-top: 5rem;
    padding-bottom: 11.8rem;

    .descr {
        width: 27.5vw;
    }

    .p34 {
        padding: 34px 0;
        background: linear-gradient(257.78deg, #1951CD -3.59%, #009FF9 103.41%);
    }

    .mtminus5 {
        margin-top: -15rem !important;
    }

    a {
        width: 100%;
        text-align: center;
    }

    .banner-kick {
        background: linear-gradient(235.48deg, #52CBFF 7.92%, #0155FF 74.27%);
        box-shadow: -6px 15px 15px rgba(1, 64, 192, 0.6);
        border: none;
        border-radius: 1rem;
        bottom: 0;
        right: 0;
        button {
            font-size: 1.4rem;
            padding: 1.7rem 5.23vw;
            width: auto;
            margin-top: 3rem;
            box-shadow: 0px 10px 15px rgba(64, 19, 3, 0.3);
        }
    }

    .kick {
        flex-wrap: wrap;
        justify-content: center;

        .logo {
            width: 14rem;
        }

        .mtminus05 {
            flex-basis: 100%;
            margin: 0;
            align-items: center !important;

            span {
                font-weight: bold !important;
                font-size: 2.431vw;
                line-height: 48px;
                margin-bottom: 0.7rem;
                margin-top: 1rem;
            }

            img {
                width: 19.584vw;
                height: 2.3vw;
            }
        }
    }


    @media ( max-width: 992px ) {
        .mtminus5 {
            margin-top: 0 !important;
        }

        .banner-kick {
            position: relative !important;
        }

        .w65 {
            margin: 0 auto;
            margin-top: 3rem;
            margin-bottom: 2rem;
        }

        .descr {
            width: 100%;
        }

        .m0auto {
            margin: 0 auto;
        }

        .fc {
            justify-content: center;
        }

        .social {
            margin: 0 auto;
            margin-top: 2rem;
            text-align: center;
        }
    }

    @media ( max-width: 768px ) {
        padding-top: 1rem;
        padding-bottom: 5rem;

        .mob {
                margin-bottom: 1rem;
                display: block;
        }

.w65 {
            width: 100%;
            margin-top: 4rem;
            padding: 2rem 1.4rem;

            h3 {
                font-size: 4.37vw;
                margin-bottom: 1rem;
            }

            .kick {
                width: 80vw;
                .logo {
                    width: 14rem;
                }

                span {
                    font-size: 7.46vw;
                    margin-bottom: 0.8rem;
                    font-weight: 600;
                }

                .title {
                    width: 60.065vw;
                    height: 7.5vw;
                }
            }

            // button {
            //     width: 100%;
            //     padding: 1.2em 0rem ;
            //     font-size: 1rem;
            //     margin-top: 2rem;
            // }

            // .kick {
            //     flex-wrap: nowrap;
            //     justify-content: space-between;

            //     .logo {
            //         width: auto;
            //         // margin-right: 1.5rem;
            //     }

            //     .mtminus05 {
            //         flex-basis: auto;
            //         align-items: flex-start !important;
            //         width: 59.375vw;

            //         span {
            //             font-weight: 600 !important;
            //             font-size: 7.5vw;
            //             line-height: 1.8rem;
            //             margin-top: 0;
            //             margin-bottom: 0.8rem;
            //         }

            //         img {
            //             width: 100%;
            //             height: auto;
            //         }
            //     }
            // }
            
        }

        .mobile-text16 {
            margin-bottom: 0;
        }

        .mobile-text-block {
            
            .mob1 {
                margin-bottom: 1.5rem;
            }

            li {
                margin-bottom: 1rem;
                line-height: 140%;
            }
        }


        .adt {
            flex-wrap: wrap;
            justify-content: flex-start;

            span {
                position: relative;
                margin-top: 2rem;
            }
        }

        .w65 {
            width: 100%;
            margin-top: 4rem;
            padding: 2rem 1.4rem;
            h3 {
                font-size: 1.4rem;
                margin-bottom: 1rem;
            }

            .kick {
                width: 80vw;
                .logo {
                    width: 5.7rem;
                }

                span {
                    font-size: 7.46vw;
                    margin-bottom: 0.8rem;
                    font-weight: 600;
                }

                .title {
                    width: 59.065vw;
                }
            }

            button {
                width: 100%;
                padding: 1.2em 0rem ;
            }

            
        }

        .social {
            text-align: left;
            margin: 0;
            width: 100%;

            .cursorp {
                margin: 0;
                padding: 0.3rem;
                padding-right: 0.8rem;
                
                margin-bottom: 1.5rem;
            }

            .flexw {
                justify-content: center;
            }

            .socials {
                justify-content: flex-start !important;

                .brf {
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    @media ( max-width: 480px ) {
            .banner-kick {
                background: linear-gradient(235.48deg, #52CBFF 7.92%, #0155FF 74.27%);
                box-shadow: -6px 15px 15px rgba(1, 64, 192, 0.6);
                border: none;
                border-radius: 1rem;

                button {
                    width: 90%;
                    margin-top: 2rem;
                    font-size: 1rem !important;
                }
            }

            .kick {
                flex-wrap: nowrap;
                justify-content: space-between;

                .logo {
                    width: 5.7rem !important;
                }

                .mtminus05 {
                    flex-basis: auto;
                    align-items: flex-start !important;
                    width: 59.375vw !important;

                    span {
                        font-weight: 600 !important;
                        font-size: 7.5vw !important;
                        line-height: 1.8rem !important;
                        margin-top: 0 !important;
                        margin-bottom: 0.8rem !important;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
    }
}
</style>