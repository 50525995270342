<template>
  <section id="payment" ref="screen">
      <div class="container">
          <div class="row">
              <div class="col-12 col-lg-5">
                  <button class="pt1 pb1 pl3 pr3 br4 bn fs10 t700 fupper twhite sh1 mb2 bglg-br mobile-label">{{ $t('payment label') }}</button>
                  <p class="fs14 t400 m0 font-o tblack mb5 lh4 mobile-text12">{{ $t('payment descr 1') }}</p>
                  
                  <h2 class="fs24 t500 font-r m0 mb1 tblack mobile-text18">{{ $t('payment title') }}</h2>
                  <p class="fs14 t400 font-o lh4 m0 mb5 tblack mb5 lh4 mobile-text12">{{ $t('payment descr 2') }}</p>

                  <h2 class="fs24 t500 font-r m0 mb1 tblack mb1 mobile-text18">{{ $t('payment title 2') }}</h2>
                  <p class="fs14 t400 font-o lh4 m0 mb2 tblack mobile-text12" :class="{mob: isMobile}">{{ $t('payment descr 3') }}</p>

                  <div class="flex flexw aic jcfs mt2 imgs" :class="{mt15: isMobile}">
                        <img class="mr1 cursorp" src="@/assets/img/pc.svg" alt="">
                        <img class="mr1 cursorp" src="@/assets/img/play.svg" alt="">
                        <img class="cursorp" src="@/assets/img/apple.svg" alt="">
                    </div>
              </div>

              <div class="col-12 col-lg-7 flex aifs jcsb mob-pay">

                    <div v-if="isMobile" class="phone-payment mtminus4 pos-r flex flexcol jcc">
                        <div class="phone-screen pos-a">
                            <img ref="items" src="@/assets/img/payments-methods.svg" alt="">
                        </div>
                        <img class="phone" src="@/assets/img/payment.svg" alt="">
                    </div>

                    <div class="btns zi5">
                        <button class="pt1 pb1 pl3 pr3 br4 bn fs10 t700 fupper twhite sh1 mb2 bglg-g" :class="{wfull: isMobile}">E-payment Integration</button>

                        <div class="payment-item pos-r sh4 br4 flex flexcol jcc pl2 pr2 pt1 ml1"
                            v-for="(item, id) in payments"
                            :key="id"
                            :class="{mt15: id > 0, pb2: id > 2, pb1: id < 3}"
                        >
                            <span class="line pos-a" :class="`bgp${++id}`"></span>

                            <h4 class="m0 fs14 t700 font-o mb05">{{ item.title }}</h4>
                            <p class="m0 lh15p fs14 t400 lh12p font-r tgrey">{{ $t(item.content) }}</p>
                        </div>
                    </div>
                
                    <div v-if="!isMobile" class="phone-payment mtminus4 pos-r flex flexcol jcc zi1">
                        <div class="phone-screen pos-a">
                            <img ref="items" src="@/assets/img/payments-methods.svg" alt="">
                        </div>
                        <img class="phone" src="@/assets/img/payment.svg" alt="">
                    </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import { TimelineLite } from 'gsap'

export default {
    props: ['isMobile', 'select'],
    data() {
        return {
            payments: [
                {title: 'Carte di Credito', content: 'payment item 1'},
                {title: 'Bonifici e Sepa', content: 'payment item 2'},
                {title: 'Digital Wallets', content: 'payment item 3'},
                {title: 'Acconti', content: 'payment item 4'},
                {title: 'Cancellazioni', content: 'payment item 5'},
            ]
        }
    },

    mounted() {
        this.animation()
    },

    watch: {
        select: {
            handler(val) {    
                if(val === 5) this.animation()
            }
        }
    },

    methods: {
        animation() {
            const { items } = this.$refs

            this.timeline = new TimelineLite({
                onComplete: () => this.timeline.restart()
            });


            this.timeline.to(items, 10, { x: '-71.4%', ease: 'none' })
            this.timeline.to(items, 10, { x: '0.6%', ease: 'none' })
        }
    }
}
</script>

<style lang="scss">
    #payment {
        padding-top: 13rem;
        padding-bottom: 14.3rem;
        overflow: hidden;

        img.mtminus4 {
            width: 60%;
        }

        .phone {
            &-payment {
                margin-right: -2.5rem;
                .phone {
                    width: 36.9rem;
                    
                }
            }

            &-screen {
                width: 60.6%;
                height: 70%;
                right: 11.4%;
                overflow: hidden;

                img {
                    position: absolute;
                    bottom: 28%;
                    transform: translateX(0.6%);
                    width: auto !important;
                }
            }
        }

        .payment-item {
            width: 28rem;

            .line {
                width: 0.5rem;
                height: 100%;
                top: 0;
                left: -1rem;
            }
        }

        .mobile-label {
            margin-top: 0;
            margin-bottom: 1.5rem;
        }

        .mobile-text18 {
            margin-bottom: 0.5rem;
        }

        .mobile-text12 {
            &.mob {
                margin-bottom: 1.5rem;
            }

            margin-bottom: 3rem;
        }

        @media ( max-width: 992px ) {

            .flex img {
                width: 20%;
                
            }

            .mob-pay {flex-wrap: wrap;}

            .imgs {
                justify-content: center;
            }

            .btns {
                flex-basis: 100%;

                button {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 2rem;
                    margin-top: 3rem;
                }
                
                .payment-item {
                    margin: 0 auto;
                    margin-bottom: 2rem;
                }
            }

            .mtminus4 {
                // width: 60% !important;
                margin: 0 auto;
            }

        }

        @media ( max-width: 768px ) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

        @media ( max-width: 576px ) {
            .mtminus4 {
                
                margin-left: -24vw !important;

                .phone {
                    width: 120vw !important;    
                }
            }

            .btns {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;

                .payment-item {
                    width: calc(100% - 15px);
                    margin: 0;
                    margin-bottom: 1rem;
                } 
            }

            .imgs {
                justify-content: space-between;
            }

            .flex img {
                width: 28.75vw;
                margin: 0;
                margin-bottom: 1.5rem;  
            }
        }
        
    }
</style>