<template>
  <div id="app" :class="{blocked: isTech}">
    <PopUp 
    :isPolicy="isPolicy" 
    :isMobile="isMobile" 
    :isMorePopUp="isMorePopUp"
    v-if="isCookiePopUp" 
    :isTech="!isTech" 
    @close-policy="closePolicy" 
    @accept-cookie="acceptCookie"
    @open-more="openMorePopUp"
    @close-more="closeMorePopUp"
    v-model="cookies"/>
    
    <Header :isMobile="isMobile" :isFixedMenu="isFixedMenu" ref="page_1" :select="selectScreen"/>
    
    <Main :isMobile="isMobile" :select="selectScreen" ref="page_2"/>

    <!-- <About :isMobile="isMobile" :isFixed="isFixed" :select="selectScreen" ref="page_2"/> -->

    

    <Footer @open-policy="openPolicy" @open-cookie="open" :isMobile="isMobile" ref="footer"/>
  </div>
</template>

<script>
import Footer from '@/screens/Footer'
import Header from '@/screens/Header'
import About from '@/screens/About'
import Screen from '@/screens/Screen'
import Main from '@/screens/Main'
import Payment from '@/screens/Payment'
import Kick from './components/Kick.vue'
import PopUp from './components/Popup.vue'
import smoothscroll from 'smoothscroll-polyfill'
import axios from 'axios'


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    About,
    Screen,
    Payment,
    Kick,
    Main,
    PopUp
  },

  data() {
    return {
      isFixed: false,
      isFixedMenu: false,
      isHide: false,
      isLeft: false,
      isCookiePopUp: true,
      isMobile: false,
      isBlockedScroll: false,
      isOpenFooter: false,
      isMorePopUp: false,
      isTech: false,
      isPolicy: false,
      cookies: {
        tech: false,
        ad: false,
        analitycs: false
      },
      position: 0,
      positionSecond: 0,
      leftPos: 0,
      heightLabel: 0,
      selectScreen: null,
      saveScreen: 0,
      savePos: 0,
      screensPosition: [],
      screensHeight: [],
      timer: null,
      screens: [
        {
          id: 'hotel',
          anim: 2,
          img: 'phones',
          imgs: [
            'hotel-1',
            'hotel-2',
            'hotel-3',
          ],
          label: 'Accomodation',
          bg: 'g',
          title: 'screen 1 title',
          content: 'screen 1 text',
          items: ['Hot Offers', 'Last-Minute', 'Special offer']
        },
        {
          id: 'food',
          anim: 3,
          img: 'food_phones',
          imgs: [
            'food-1',
            'food-2',
            'food-3',
          ],
          label: 'In the village',
          bg: 'p',
          title: 'screen 2 title',
          content: 'screen 2 text',
          items: ['Book your Table', 'Delivery Service', 'Take Away']
        },
        {
          id: 'ski',
          anim: 4,
          img: 'ski_phones',
          imgs: [
            'ski-1',
            'ski-2',
            'ski-3',
          ],
          label: 'Ski and Snowboard Life',
          bg: 'br',
          title: 'screen 3 title',
          content: 'screen 3 text',
          items: ['Individual and group Lessons', 'Discover Alps Tours and Ski Safari', 'Ski & Snowboard Test']
        }
      ]
    }
  },

  created() { 
    window.addEventListener('scroll', this.scroll);

    this.clearCookie()
    smoothscroll.polyfill()

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

    this.selectScreen = 0

    Object.keys(this.cookies).map(item => {
      this.cookies[item] = true
    })

  },

  mounted() {
    const mediaQuery = window.matchMedia("(max-width:768px)");
    this.isMobile = mediaQuery.matches;
    const listener = e => this.isMobile = e.matches;
    mediaQuery.addListener(listener);
    this.$once('hook:beforeDestroy', () => mediaQuery.removeListener(listener));


    if(this.isMobile) this.screens[2].items = ['Individual and group Lessons', 'Alps Tours and Ski Safari', 'Ski & Snowboard Test']
  },

  watch: {
    cookies: {
      handler(val) {
        console.log()
      }, 
      deep: true
    },

    isCookiePopUp: {
      handler(val) {
        if(!val) {
          if(this.cookies.ad) this.connectPixelScript()
          if(this.cookies.analitycs) this.connectScriptsAnalytics()
        }
      }
    },


    selectScreen: {
      handler(val) {

      }
    }
  },

  methods: {
    scroll(val) {
      // const isPagePos = window.scrollY + window.innerHeight 

      // this.isOpenFooter = false

      this.isFixedMenu = window.scrollY >= 1
      // this.isFixed = isPagePos > this.$refs.page_3[0].$refs.screen.offsetTop

      // if(this.$refs.footer) this.isHide = isPagePos >= this.$refs.footer.$refs.footer.offsetTop

      // this.saveScreen = this.selectScreen

      // let screensHeight = []

      // for(let i = 0; i < 6; i++) {
      //   const screen = this.$refs[`page_${i+1}`]

      //   if(i < 2 || i > 4) {
      //     this.screensPosition[i] = screen.$refs.screen.offsetTop
      //     screensHeight[i] = screen.$refs.screen.clientHeight
      //   } else  {
      //     this.screensPosition[i] = screen[0].$refs.screen.offsetTop
      //     screensHeight[i] = screen[0].$refs.screen.clientHeight
      //   }

      // }

      // if(this.isMobile) { if(window.scrollY >= this.screensPosition[this.selectScreen + 1] + screensHeight[this.selectScreen + 1] / 15) ++this.selectScreen }
      // else { if(isPagePos >= this.screensPosition[this.selectScreen + 1] + screensHeight[this.selectScreen + 1] / 3) ++this.selectScreen }

      // if(isPagePos > 2000) this.isLeft = true
      // else this.isLeft = false
    },

    open() {
      this.isMorePopUp = true
      this.isCookiePopUp = true
      this.isPolicy = false
    },

    openMorePopUp() {
      this.isMorePopUp = true
    },

    closeMorePopUp() {
      this.isMorePopUp = false
    },

    openPolicy() {
      this.isCookiePopUp = true
      this.isPolicy = true
    },

    closePolicy() {
      this.isCookiePopUp = false
      this.isPolicy = false
    },
    

    acceptCookie(accept) {
      this.selectScreen = 0
      this.isTech = false

      console.log(this.isCookiePopUp && this.isTech)

      if(accept === 'full') {
        Object.keys(this.cookies).map(item => {
          this.cookies[item] = true
        })

        this.isCookiePopUp = false
      } else {
        this.isCookiePopUp = false
      }

      const { tech, ad, analitycs } = this.cookies

      const data = {
        action: 'COOKIES',
        teh: Number(tech),
        an: Number(analitycs),
        pb: Number(ad)
      }

      var formDataSendCookie = new FormData();

      Object.keys(data).map(item => formDataSendCookie.append(item, data[item]))

      axios.post('https://announce.cerviniaresort.com/back/get_info.php', formDataSendCookie).then(res => {
          console.log(res)
      })
      .catch(rej => console.log(rej))

    },

    connectScriptsAnalytics() {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      gtag('js', new Date());
      gtag('config', 'G-YFV94TJRKD');
    },

    connectPixelScript() {
        !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', '471769133792487');
      fbq('track', 'PageView');
    },

    clearCookie() {
      var cookies = document.cookie.split(";");

      console.log(cookies)

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  }
}
</script>

<style lang="scss">
.blocked {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

button {
  transition: .4s;
  &:hover {
    opacity: 0.8;
  }
}

.h17 {
  height: 17px;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  z-index: -999;
  // z-index: 899;
  position: fixed;
  bottom: 0;
  transition: .5s;
}

.overlay-active {
  transition: .5s;
  z-index: 899;
  opacity: 1
}

.app {
  overflow: hidden;
}

.z99 {
  z-index: 99 !important;
}

.kick-fot {
  height: 75vh;
  width: 100vw;
  
  
  bottom: 0;
  position: fixed;
  background: linear-gradient(257.78deg,  #009FF9 -3.59%, #0039B9 103.41%);
  z-index: 999;
  padding-top: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  transition: .5s;

  .button-kick {
    padding: 0.8rem;
    top: -4.6rem;
    left: 1.5rem;
    background: linear-gradient(268.52deg, #025FCD 0.33%, #0243BE 71.93%);

    .kick {
      width: auto;
    }

    .logo {
      width: 3rem;
    }

    .mtminus05 {
      margin-left: 0.5em;
    }

    .title {
      width: 10rem;
    }

    span {
      font-size: 1.2rem;
      margin-bottom: 0.3rem;
      line-height: 1.4rem;
    }
  }

  .scroll-over {
    height: 100%;
    overflow: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.kick-none {
  bottom: -100vh !important;
  transition: .5s;
}

.kick-bar {
  bottom: 75vh;
  position: fixed;
  transition: .5s;
  z-index: -1 !important;
}

.button-kick {
  padding: 11px 25px;
  border-radius: 8px 8px 0px 0;
  right: 0;
  transition: 1.5s;
  background: linear-gradient(268.52deg, #015FD1 0.33%, #014BC4 108.6%, #0160BD 108.6%);
}

.fixed {
  right: calc(100% - 299.6px) !important;
  background: linear-gradient(268.52deg, #015FD1 0.33%, #014BC4 108.6%, #0160BD 108.6%) !important;
}

.mobile-bar {
  height: 1rem;
  background: linear-gradient(268.52deg, #025FCD 0.33%, #0243BE 71.93%) !important;

  .button-kick {
    padding: .8rem;
    background: linear-gradient(268.52deg, #025FCD 0.33%, #0243BE 71.93%);
  }

  .ml15 {
    margin-left: 0.5rem;
  }

  .logo {
    width: 3rem;
  }

  .title {
    width: 10rem;
  }

  span {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
    line-height: 1.4rem;
  }
}

.nf {
  height: 0;
}

.r-a { right: auto; }

@media ( max-width: 992px ) {
  .bar {
    display: none !important;
  }
}

</style>
