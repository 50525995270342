<template>
  <div class="">
      <h3 class="fs16 font-o t700 lh22p m0 mb15">Informativa Privacy sulla protezione dei dati personali ai sensi art. 13 REG.UE 2016/679</h3>
      <p class="fs14 font-r t400 lh3 m0 mb1">
La informiamo con la presente che il REG.UE 2016 prevede la tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali.
Ai sensi della predetta normativa, il trattamento dei Suoi dati personali sarà improntato ai principi di correttezza, liceità e trasparenza e di tutela della Sua riservatezza e dei Suoi diritti. L’informativa non è da considerarsi valida per altri siti web eventualment e consultabili tramite link presenti sui siti Internet a dominio del titolare, che non è da considerarsi in alcun modo responsabile dei siti internet dei terzi.
      </p>
      <p class="fs14 font-r t400 lh3 m0">
Questo sito web e altri siti o applicazione del Titolare raccoglie alcuni Dati Personali dei propri Utenti. <br>
Questo documento può essere stampato utilizzando il comando di stampa presente nelle impostazioni di qualsiasi browser. La presente informativa ha lo scopo di consentire agli utenti che navighino nel nostro Sito di conoscere, anche prima di accedere alle varie sezioni del Sito/APP, in che modo www.cerviniaresort.com a tratta i dati personali degli utenti e sarà comunque necessario che l’utente ne prenda visione prima che egli conferisca propri dati personali all’atto della registrazione sul Sito/APP.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Tipologie di Dati raccolti</h3>
      <p class="fs14 font-r t400 lh3 m0 mb1">
Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: Cookie; Dati di utilizzo; email; Dati comunicati durante l'utilizzo del servizio; indirizzo; password; nazione; stato; provincia; città; dati relativi al pagamento; nome; cognome; CAP; numero di telefono.
      </p>
      <p class="fs14 font-r t400 lh3 m0 mb1">
Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.
I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.
Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare. <br>
L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.
      </p>
      <p class="fs14 font-r t400 lh3 m0">
L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione 
e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Trattamento dei Dati</h3>
      <p class="fs14 font-r t400 lh3 m0">
www.cerviniaresort.com  <br>
Indirizzo email del Titolare: info@cerviniaresort.com
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Oggetto del trattamento</h3>
      <p class="fs14 font-r t400 lh3 m0">
Il Titolare tratta i dati personali, identificativi e non sensibili (a titolo esemplificativo ma non esaustivo: nome, cognome, ragione sociale, indirizzo, telefono, e-mail – in seguito, “dati personali” o anche “dati”) da Voi comunicati in occasione della registrazione a questo sito web (in seguito, “Sito”), della partecipazione a sondaggi di opinione e di gradimento, della compilazione di form di iscrizione tramite il Sito e da richieste on-line.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Modalità del trattamento e periodo di conservazione dei dati</h3>
      <p class="fs14 font-r t400 lh3 m0 mb1">
Il trattamento dei Vostri dati personali è realizzato per mezzo delle operazioni indicate all’art. 4 Codice Privacy e all’art. 4 n. 2) GDPR e precisamente: raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. I Vostri dati personali sono sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato, mediante l’uso di sito web ospitato sul server del titolare del trattamento o su siti di società esterne che permettono al titolare del trattamento di offrire i suoi servizi (come ad esempio per offrire assistenza chat online o depositare file per il cliente fornendo la sua email e nominativo per lo scaricamento).
      </p>
      <p class="fs14 font-r t400 lh3 m0 mb1">
Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
      </p>
      <p class="fs14 font-r t400 lh3">
Il Titolare tratterà i dati personali per il tempo necessario per adempiere alle finalità di cui sopra e comunque per non oltre 10 anni dalla cessazione del rapporto per le finalità di servizio e per non oltre 2 anni dalla raccolta dei dati per le altre finalità. Nel rispetto di quanto previsto dall’art. 5 comma 1 lett. e) del Reg. UE 2016/679 i dati personali raccolti verranno comunque conservati in una forma che consenta l’identificazione degli interessati per un arco di tempo non superiore al conseguimento delle finalità per le quali i dati personali sono trattati.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Finalità del trattamento</h3>
      <p class="fs14 font-r t400 lh3 m0">
I Suoi dati personali ci sono stati forniti e saranno trattati per le seguenti finalità: <br><br>
- gestire e mantenere il Sito o permettervi accessi ad aree dedicate;<br><br>
- rispondere alle chat online di contatto;<br><br>
- garantirvi gli accessi a programmi e servizi;<br><br>
- offrire assistenza e consulenza anche da remoto;<br><br>
- processare una richiesta di contatto;<br><br>
- per attività amministrativo-contabili in genere;<br><br>
- prevenire o scoprire attività fraudolente o abusi dannosi per il Sito;<br><br>
- esercitare i diritti del Titolare, ad esempio il diritto di esercizio di un diritto in sede giudiziaria.<br><br>
- l’aggiornamento dell’utente sui nuovi prodotti o servizi erogati dal sito o in relazione a particolari offerte commerciali;<br><br>
- l’informazione dell’utente in occasione di manutenzione sul sito o di eventuali disservizi;<br><br>
- la difesa da parte del titolare del sito, in giudizio o nelle fasi propedeutiche alla sua eventuale instaurazione, da abusi nell’utilizzo dello stesso o dei servizi connessi da parte dell’utente.<br><br>
- per l’inserimento delle anagrafiche nei data base informatici aziendali;<br><br>
- per la gestione degli incassi e pagamenti;<br><br>
- per adempiere agli obblighi previsti dalle norme di legge civilistiche e fiscali, dai regolamenti, dalla normativa comunitaria.<br><br>
- per le attività commerciali e di marketing connesse alla nostra attività d’impresa ivi compresa la profilazione
gestire tutte quelle attività primarie strettamente funzionali al soddisfacimento delle sue richieste e alla fornitura del servizio da lei liberamente scelto quali; la pubblicazione dei suoi commenti, la gestione di eventuali richieste di informazioni nonché la gestione di tutte le informazioni necessarie per l’erogazione degli abbonamenti alle relative edizioni digitali delle testate;<br><br>
- svolgere, previo suo consenso, attività promozionali, commerciali e pubblicitarie su eventi, iniziative o prodotti, nonché attività finalizzate a conoscere le esigenze del cliente, le loro opinioni, elaborare studi, ricerche e statistiche di mercato e svolgere sondaggi connessi ai prodotti. Per gli scopi sopradescritti, lei potrà essere contattato per ricerca di mercato o direct-marketing attraverso posta tradizionale, e-mail, newsletter, chiamate tramite operatore, fax, instant messaging, post, messaggi e comunicazioni di iniziative su social network o canali informatici di altro tipo;<br><br>
-svolgere attività di profilazione al fine di personalizzare la nostra offerta commerciale elaborando un suo profilo, determinando, preferenze, gusti, abitudini, necessità e scelte di consumo e fornirle comunicazioni di carattere commerciale, promozionale e pubblicitario in linea con il profilo individuato;<br><br>
- erogare e gestire i vari servizi offerti; nonché consentire la registrazione sul Sito/APP, che è necessaria per l’accesso a particolari sezioni del Sito/APP stesso e per erogare e gestire i vari servizi offerti;<br><br>
- consentire agli utenti di pubblicare i Contributi direttamente sul Sito/APP, ovvero su siti gestiti autonomamente da terze parti con le quali eventualmente Cervinia Resort abbia raggiunto accordi in tal senso, quali, a titolo esemplificativo e non esaustivo, social network quali Facebook, Twitter, ecc. (di seguito “Social Network”);<br><br>
- nel rispetto dei requisiti di legge ed al fine di personalizzare l’esperienza degli utenti sul Sito/APP e migliorare servizi e prodotti offerti alla propria clientela o trasmettere pubblicità profilata (con email, banner e contenuti dinamici sul Sito) sulla base dei gusti e della navigazione degli utenti, previo consenso dell’utente e fino alla revoca dello stesso, effettuare analisi sulle abitudini o scelte di consumo e definire il profilo degli interessati utilizzando le informazioni fornite da questi ultimi al momento della registrazione, ovvero in occasione della compilazione di questionari o sulla base di azioni compiute o delle informazioni fornite durante la navigazione sul Sito/APP. Queste finalità saranno poste in essere anche mediante l’utilizzo di cookie;<br><br>
- previo consenso dell’utente, e fino alla revoca dello stesso, effettuare attività di raffronto e combinazione, a fini di profilazione, di dati personali dell’utente stesso presenti in diverse banche dati di o in uso a Cervinia Resort.<br><br>
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Luogo del trattamento</h3>
      <p class="fs14 font-r t400 lh3 m0">
I Dati Personali sono trattati principalmente presso la sede del Titolare e nei luoghi in cui si trovano il Responsabile e gli autorizzati frazione Breuil Cervinia, 11028, Valtournenche AO, Italia. Per ulteriori informazioni, contatta il Titolare info@cerviniaresort.com . I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali. L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.
<br><br>L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.
<br><br>Pertanto: <br> I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
<br><br>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.
<br><br>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.
<br><br>Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.
<br><br>Il trattamento sarà effettuato sia con strumenti manuali e/o informatici e telematici con logiche di organizzazione ed elaborazione strettamente correlate alle finalità stesse e comunque in modo da garantire la sicurezza, l’integrità e la riservatezza dei dati stessi nel rispetto delle misure organizzative, fisiche e logiche previste dalle disposizioni vigenti.
<br><br>I dati personali sono conservati presso i server di GoDaddy Operating Company, LLC, tali server sono collocati all’interno dell’Unione Europea. Resta in ogni caso inteso che il Titolare, ove si rendesse necessario, avrà facoltà di spostare i server anche extra-UE. In tal caso, il Titolare assicura sin d’ora che il trasferimento dei dati extra-UE avverrà in conformità alle disposizioni di legge applicabili, previa stipula delle clausole contrattuali standard previste dalla Commissione Europea.https://it.godaddy.com/legal/agreements/privacy-policy
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Fondamento di liceità del trattamento – basi giuridiche</h3>
      <p class="fs14 font-r t400 lh3 m0">
I fondamenti di liceità del trattamento sono indicati all’art. 6 del GDPR e sono per la presente informativa il consenso e l’adempimento di obblighi contrattuali:
<br><br>- pubblicazione contributi	Consenso dell’interessato;
<br><br>- marketing	Consenso dell’interessato;
<br><br>- profilazione	Consenso dell’interessato;
<br><br>- rispondere a richieste dell’utente	Esecuzione di misure contrattuali e precontrattuali su richiesta dell’utente;
<br><br>- profilazione mediante raffronti e combinazioni di base dati	Consenso dell’interessato;
<br><br>- per l’inserimento delle anagrafiche nei data base informatici aziendali;
<br><br>- per la gestione degli incassi e pagamenti;
<br><br>- trattamento necessario all’esecuzione di un contratto di cui l’interessato è parte o all’esecuzione di misure precontrattuali adottate su richiesta dello stesso;
<br><br>- per adempiere agli obblighi previsti dalle norme di legge civilistiche e fiscali, dai regolamenti, dalla normativa comunitaria.	trattamento è necessario per adempiere un obbligo legale al quale è soggetto il titolare del trattamento;
<br><br>- l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;
<br><br>- il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;
<br><br>- il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
<br><br>È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Tipologia di dati personali trattati</h3>
      <p class="fs14 font-r t400 lh3 m0">
Durante la navigazione sul Sito Web possono essere raccolti dati suoi personali ovverosia tutte quelle informazioni che possono essere utilizzate per identificare un utente direttamente o indirettamente. I dati che possono essere raccolti sono i seguenti:Oltre ai Dati Personali conferiti direttamente dagli utenti ( quali ad esempio nome, cognome, e-mail, telefono, luogo e data di nascita, ecc. Sono tutti dati forniti liberamente dall’utente attraverso la compilazione di appositi form per ricevere servizi specifici forniti dal Titolare, ecc.), in fase di connessione al Sito, i sistemi informatici e le procedure software preposte al funzionamento del Sito stesso somministrano e/o acquisiscono automaticamente ed indirettamente alcune informazioni che potrebbero costituire dati personali, la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet (quali, in via meramente esemplificativa ma non esaustiva, i c.d. “cookie” (come meglio di seguito specificato), indirizzi “IP”, nomi di dominio dei computer utilizzati dagli utenti che si connettono al Sito, gli indirizzi in notazione “Url” delle risorse richieste, l’orario della richiesta al server, la navigazione sul Sito/APP).
      </p>
      
      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Dettagli sul trattamento dei Dati Personali</h3>
      <p class="fs14 font-r t400 lh3 m0">
I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:
<br><br>Contattare l'Utente
<br><br>Mailing list o newsletter
<br><br>Con la registrazione alla mailing list o alla newsletter, l’indirizzo email dell’Utente viene automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi email contenenti informazioni, anche di natura commerciale e promozionale, relative a questa Applicazione. L'indirizzo email dell'Utente potrebbe anche essere aggiunto a questa lista come risultato della registrazione a questa Applicazione o dopo aver effettuato un acquisto.
<br><br>Dati Personali trattati: CAP; città; cognome; email; indirizzo; nome; stato.
<br><br>
<br><br>Gestione contatti e invio di messaggi
<br><br>Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di qualunque altro tipo, utilizzati per comunicare con l’Utente.
<br><br>Questi servizi potrebbero inoltre consentire di raccogliere dati relativi alla data e all’ora di visualizzazione dei messaggi da parte dell’Utente, così come all’interazione dell'Utente con essi, come le informazioni sui click sui collegamenti inseriti nei messaggi.
<br><br>
<br><br>Gestione dei database di Utenti
<br><br>Questo tipo di servizi permettono al Titolare di costruire profili utente partendo da un indirizzo email, il nome o qualunque altra informazione che l'Utente fornisce a questa Applicazione, così come di tracciare le attività dell'Utente tramite funzionalità statistiche. Questi Dati Personali potrebbero inoltre venire incrociati con informazioni sull'Utente disponibili pubblicamente (come i profili sui social network) ed usati per costruire profili privati che il Titolare può visualizzare ed utilizzare per migliorare questa Applicazione.
<br><br>Alcuni di questi servizi potrebbero inoltre permettere l'invio programmato di messaggi all'Utente, come email basate su azioni specifiche compiute su questa Applicazione.
<br><br>
<br><br>Gestione dei pagamenti
<br><br>Se non diversamente specificato, questa Applicazione elabora tutti i pagamenti con carta di credito, bonifico bancario o altri mezzi tramite fornitori esterni di servizi di pagamento. In generale, e salvo diversa indicazione, gli Utenti sono pregati di fornire i dettagli di pagamento e le informazioni personali direttamente a tali fornitori di servizi di pagamento.
<br><br>Questa Applicazione non è coinvolta nella raccolta e nell'elaborazione di tali informazioni: riceverà invece solo una notifica da parte del fornitore di servizi di pagamento in questione circa l'avvenuto pagamento.
<br><br>
<br><br>Stripe.com (Stripe)
<br><br>Stripe  è un servizio di pagamento fornito da stripe.com , che consente all’Utente di effettuare pagamenti online.
<br><br>Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
<br><br>
<br><br>Kickstarter.com (KickStarter)Kickstarter e un servizio di raccolta fondi per startup inovative, che consente al utente di effetuare donazioni online. Dati personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
<br><br>
<br><br>Luogo del trattamento: Consulta la privacy policy di Stripe – Privacy Policy.
<br><br>
<br><br>Pagamento a mezzo di bonifico (questa Applicazione)
<br><br>Nel caso in cui il metodo di pagamento scelto sia il bonifico bancario diretto sul conto corrente indicato da questa Applicazione, il Titolare raccoglierà i dati relativi al pagamento dell'Utente, ossia il numero di conto corrente del disponente, il codice SWIFT, la Banca ed il nominativo del disponente. Tali dati saranno raccolti e trattati esclusivamente nell'ambito della transazione ed ai soli fini della fatturazione.
<br><br>Dati Personali trattati: cognome; dati relativi al pagamento; nome.
<br><br>
<br><br>Gestione dei tag
<br><br>Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script utilizzati su questa Applicazione.
<br><br>L'uso di tali servizi comporta il fluire dei Dati dell'Utente attraverso gli stessi e, se del caso, la loro ritenzione.
<br><br>
<br><br>Google Tag Manager
<br><br>Google Tag Manager è un servizio di gestione dei tag fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui questa Applicazione viene utilizzata.
<br><br>Dati Personali trattati: Dati di utilizzo.
<br><br>
<br><br>Luogo del trattamento: Stati Uniti – Privacy Policy; Irlanda – Privacy Policy.
<br><br>
<br><br>Interazione con social network e piattaforme esterne
<br><br>Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne, direttamente dalle pagine di questa Applicazione.
<br><br>Le interazioni e le informazioni acquisite da questa Applicazione sono in ogni caso soggette alle impostazioni privacy dell’Utente relative ad ogni social network.
<br><br>Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio è installato, anche quando gli Utenti non lo utilizzano.
<br><br>Si raccomanda di disconnettersi dai rispettivi servizi per assicurarsi che i dati elaborati su questa Applicazione non vengano ricollegati al profilo dell'Utente.
<br><br>
<br><br>Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)
<br><br>Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Pulsante Follow US e widget sociali di Facebook (Facebook, Inc.)
<br><br>Il pulsante “Follow US” e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Pulsante Gropu Facebook e widget sociali di Facebook (Facebook, Inc.)
<br><br>Il pulsante “Follow US” e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Luogo del trattamento: Stati Uniti – Privacy Policy.
<br><br>
<br><br>Interazione con le piattaforme di live chat
<br><br>Questo tipo di servizi permette di interagire con le piattaforme di live chat, gestite da soggetti terzi, direttamente dalle pagine di questa Applicazione. Ciò permette all'Utente di contattare il servizio di supporto di questa Applicazione o a questa Applicazione di contattare l'Utente mentre sta navigando le sue pagine.
<br><br>Nel caso in cui sia installato un servizio di interazione con le piattaforme di live chat, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga Dati di Utilizzo relativi alle pagine in cui è installato. Inoltre, le conversazioni della live chat potrebbero essere registrate.
<br><br>
<br><br>Registrazione ed autenticazione fornite direttamente da questa Applicazione
<br><br>Con la registrazione o l’autenticazione l’Utente consente a questa Applicazione di identificarlo e di dargli accesso a servizi dedicati. I Dati Personali sono raccolti e conservati esclusivamente a scopo di registrazione o di identificazione. I Dati raccolti sono solo quelli necessari a fornire il servizio richiesto dall’Utente.
<br><br>
<br><br>Telegram
<br><br>Pulsante Contact US e widget sociali di Telegram (Telegram, Inc.)
<br><br>Il pulsante “contact US” e i widget sociali di Telegram sono servizi di interazione con il social networkTelegram.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Pulsante Folow Us e widget sociali di Telegram (Telegram, Inc.)
<br><br>Il pulsante “contact US” e i widget sociali di Telegram sono servizi di interazione con il social networkTelegram.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Whatsapp
<br><br>Pulsante contact us e widget messenger
<br><br>Il pulsante “contact US” e i widget sociali di Telegram sono servizi di interazione con il social networkTelegram.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Registrazione diretta (questa Applicazione)
<br><br>L’Utente si registra compilando il modulo di registrazione e fornendo direttamente a questa Applicazione i propri Dati Personali.
<br><br>Dati Personali trattati: città; email; indirizzo; nazione; password; provincia; stato.
<br><br>
<br><br>Pubblicità
<br><br>Questo tipo di servizi consente di utilizzare i Dati dell’Utente per finalità di comunicazione commerciale. Queste comunicazioni sono mostrate su questa Applicazione sotto forma di banner e altre forme pubblicitarie, anche in relazione agli interessi dell’Utente.
<br><br>Ciò non significa che tutti i Dati Personali vengano utilizzati per questa finalità. Dati e condizioni di utilizzo sono indicati di seguito.
<br><br>Alcuni dei servizi di seguito indicati potrebbero utilizzare Cookie o altri Identificatori per identificare l’Utente o utilizzare la tecnica del behavioral retargeting, ossia visualizzare annunci pubblicitari personalizzati in base agli interessi e al comportamento dell’Utente, rilevati anche al di fuori di questa Applicazione. Per avere maggiori informazioni in merito, ti suggeriamo di verificare le informative privacy dei rispettivi servizi.
<br><br>In aggiunta alle funzionalità di opt-out offerte dai servizi di seguito riportati, l'Utente può effettuare l’opt-out visitando la pagina di opt-out della Network Advertising Initiative.
<br><br>
<br><br>Gli Utenti possono anche scegliere di non partecipare a determinate funzionalità pubblicitarie attraverso le corrispondenti opzioni di configurazione del dispositivo, come le opzioni di configurazione pubblicitaria del dispositivo mobile o la configurazione pubblicitaria generica.
<br><br>
<br><br>Direct marketing tramite SMS (questa Applicazione)
<br><br>Questa Applicazione utilizza i Dati dell’Utente per inviare proposte di natura commerciale relativi a servizi e prodotti forniti da terze parti o non correlati al prodotto o servizio forniti da questa Applicazione.
<br><br>Dati Personali trattati: cognome; nome; numero di telefono.
<br><br>
<br><br>Direct Email Marketing (DEM) (questa Applicazione)
<br><br>Questa Applicazione utilizza i Dati dell’Utente per inviare proposte di natura commerciale relativi a servizi e prodotti forniti da terze parti o non correlati al prodotto o servizio forniti da questa Applicazione.
<br><br>Dati Personali trattati: cognome; email; nome.
<br><br>
<br><br>Direct Social Media Marketing (questa Applicazione)
<br><br>Questa Applicazione utilizza i Dati dell’Utente per inviare proposte di natura commerciale relativi a servizi e prodotti forniti da terze parti o non correlati al prodotto o servizio forniti da questa Applicazione.
<br><br>Dati Personali trattati: cognome; email; nome.
<br><br>
<br><br>Visualizzazione di contenuti da piattaforme esterne
<br><br>Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi.
<br><br>Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico web relativo alle pagine dove il servizio è installato, anche quando gli utenti non lo utilizzano.
<br><br>
<br><br>Google Fonts
<br><br>Google Fonts è un servizio di visualizzazione di stili di carattere gestito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui questa Applicazione viene utilizzata, che permette a questa Applicazione di integrare tali contenuti all’interno delle proprie pagine.
<br><br>Dati Personali trattati: Dati di utilizzo; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
<br><br>
<br><br>Statistica
<br><br>I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
<br><br>
<br><br>Google Analytics
<br><br>Google Analytics è un servizio di analisi web fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui questa Applicazione viene utilizzata, (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.
<br><br>Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>
<br><br>Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out; Irlanda – Privacy Policy – Opt Out.
<br><br>
<br><br>Monitoraggio conversioni di Facebook Ads (pixel di Facebook) (Facebook, Inc.)
<br><br>Il monitoraggio conversioni di Facebook Ads (pixel di Facebook) è un servizio di statistiche fornito da Facebook, Inc. Che collega i dati provenienti dal network di annunci Facebook con le azioni compiute all'interno di questa Applicazione. Il pixel di Facebook monitora le conversioni che possono essere attribuite alle inserzioni di Facebook, Instagram e Audience Network.
<br><br>Dati Personali trattati: Cookie; Dati di utilizzo.
<br><br>Luogo del trattamento: Stati Uniti – Privacy Policy.

      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Diritti dell’interessato</h3>
      <p class="fs14 font-r t400 lh3 m0">
In ogni momento potrà esercitare i Suoi diritti nei confronti del titolare del trattamento che per Sua comodità riproduciamo
<br><br>– Accesso (art. 15 Regolamento UE n. 2016/679); 
<br><br>– Rettifica (art. 16 Regolamento UE n. 2016/679); 
<br><br>– Cancellazione (art. 17 Regolamento UE n. 2016/679);
<br><br>– Limitazione (art. 18 Regolamento UE n. 2016/679); 
<br><br>– Portabilità, intesa come diritto ad ottenere dal titolare del trattamento i dati in un formato strutturato di uso comune e leggibile da dispositivo automatico per trasmetterli ad un altro titolare del trattamento senza impedimenti (art. 20 Regolamento UE n. 2016/679);
<br><br>– Opposizione al trattamento (art. 21Regolamento UE n. 2016/679);
<br><br>– Revoca del consenso al trattamento, senza pregiudizio per la liceità del trattamento basata sul consenso acquisito prima della revoca (art. 7, par. 3 Regolamento UE n. 2016/679);
<br><br>– Proporre reclamo all’Autorità Garante per la Protezione dei dati personali (art. 51 Regolamento UE n. 2016/679).
<br><br>
<br><br>Dettagli sul diritto di opposizione
<br><br>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.
<br><br>
<br><br>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.
<br><br>
<br><br>Come esercitare i diritti
<br><br>Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.
<br><br>
<br><br>Diritto di accesso
<br><br>L’Interessato ha diritto di ottenere dal Titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano. Il Titolare del trattamento fornisce una copia dei dati personali oggetto di trattamento; in caso di ulteriori copie richieste dall’interessato, il Titolare del trattamento può addebitare un contributo spese ragionevole basato sui costi amministrativi.

      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Tempo del trattamento e di conservazione dei dati</h3>
      <p class="fs14 font-r t400 lh3 m0">
I dati raccolti per finalità di erogazione dei servizi primari richiesti dall’interessato saranno conservati per il periodo strettamente necessario a conseguire la finalità stessa. Tuttavia, il Titolare potrà continuare a conservare tali dati per un periodo superiore, ma comunque nel rispetto della normativa vigente, al fine di poter gestire eventuali contestazioni legate alla fornitura dei servizi.
<br><br><br><br>I dati raccolti per finalità di marketing e profilazione saranno conservati per il periodo previsto dalla normativa vigente che non eccede i 24 mesi dalla data di rilascio del suo consenso. Al termine di tale periodo i suoi dati saranno cancellati o resi anonimi.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Categorie di soggetti che potranno venire a conoscenza dei Dati Personali degli utenti</h3>
      <p class="fs14 font-r t400 lh3 m0">
<br><br>I Dati Personali potranno essere portati a conoscenza di dipendenti o collaboratori del Titolare che, operando sotto la diretta autorità di quest’ultimo, trattano dati e sono autorizzati del trattamento e che riceveranno al riguardo adeguate istruzioni operative dal Titolare.
<br><br>I Dati Personali potranno essere altresì portati a conoscenza di Responsabili esterni del Titolare, se autorizzati con consenso specifico ,come società terze o altri soggetti (ad esempio, soggetti a cui viene affidata attività di assistenza, comunicazione, marketing, pubblicità, promozioni e vendita di prodotti e/o servizi, inserzionisti, concessionarie pubblicitarie, fornitori di servizi IT, gestori di Siti, gestori delle Piattaforme elettroniche) – che svolgono attività in outsourcing per conto del Titolare.
<br><br>I Suoi dati personali, ai fini dell’esecuzione del contratto e per le finalità sopra indicate, potranno essere comunicati:
<br><br>-a tutte le persone fisiche e giuridiche (studi di consulenza legale, amministrativa, fiscale, società di revisione, corrieri e spedizionieri, centro elaborazione dati, partner commerciali società del gruppo, canali social etc.) nei casi in cui la comunicazione risulti necessaria per le finalità sopra illustrate;
<br><br>-ad istituti bancari per la gestione degli incassi e dei pagamenti;
<br><br>-a società di factoring o di recupero crediti;
<br><br>-ai nostri collaboratori e dipendenti appositamente autorizzati e nell’ambito delle relative mansioni;
<br><br>-a dipendenti e collaboratori del Titolare, nella loro qualità di incaricati e/o responsabili interni del trattamento e/o amministratori di sistema;
<br><br>-a società terze o altri soggetti (provider sito web, cloud provider, provider servizio e-payment, fornitori, tecnici addetti all’assistenza hardware e software, spedizionieri e vettori, istituti di credito, studi professionali, etc.) che svolgono attività in outsourcing per conto del Titolare, nella loro qualità di responsabili del trattamento.
<br><br>Senza il Vostro espresso consenso (ex art. 24 lett. a), b), d) Codice Privacy e art. 6 lett. b) e c) GDPR), il Titolare potrà comunicare i Vostri dati per le finalità di cui all’art. 2.A) a Organismi di vigilanza e Autorità giudiziarie, nonché a tutti gli altri soggetti ai quali la comunicazione sia obbligatoria per Legge. Si assicura comunque che i Vostri dati personali non saranno mai resi pubblici sul sito del titolare.
<br><br>
<br><br>Trasferimento dati
<br><br>La gestione e la conservazione dei dati personali avverrà in Europa, su server ubicati in UE e/o di società terze anche all’estero, incaricate e debitamente nominate quali Responsabili del trattamento per l’utilizzo dei servizi richiesti. I dati di natura personale forniti potranno essere trasferiti all’estero dentro e fuori l’Unione Europea, nei limiti e alle condizioni di cui agli artt. 44 e ss. del Regolamento UE 2016/679, al fine di ottemperare a finalità connesse al trasferimento medesimo.
<br><br>
<br><br>Ulteriori informazioni sul trattamento
<br><br>Difesa in giudizio
<br><br>I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o dei Servizi connessi da parte dell’Utente.
<br><br>L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.
<br><br>
<br><br>Informative specifiche
<br><br>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.
<br><br>
<br><br>Log di sistema e manutenzione
<br><br>Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.
<br><br>
<br><br>Informazioni non contenute in questa policy
<br><br>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.
<br><br>
<br><br>Risposta alle richieste “Do Not Track”
<br><br>Questa Applicazione non supporta le richieste “Do Not Track”.
<br><br>Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive privacy policy.
<br><br>
<br><br>Modifiche a questa privacy policy
<br><br>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.
<br><br>
<br><br>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.
<br><br>
<br><br>Comunicazione all’interessato di una violazione dei dati personali (data breach)
<br><br>Quando la violazione dei dati personali è suscettibile di presentare un rischio elevato per i diritti e le libertà delle persone fisiche, il Titolare del trattamento comunica la violazione all’interessato senza ingiustificato ritardo (Art. 34 Regolamento UE n. 2016/679). Non è richiesta la comunicazione nel caso in cui il Titolare del trattamento ha messo in atto adeguate misure tecniche e organizzative di protezione o nel caso in cui la comunicazione richiederebbe sforzi sproporzionati.
<br><br>
<br><br>Sicurezza
<br><br>Il Titolare ha adottato una grande varietà di misure di sicurezza per proteggere i Vostri dati contro il rischio di perdita, abuso o alterazione. In particolare: ha adottato le misure di cui agli artt. 32-34 Codice Privacy e all’art. 32 GDPR. Utilizza, qualora sia necessario per comunicazioni più sicure, la tecnologia di criptaggio dei dati stabilita dagli Standard AES (BCrypt) ed i protocolli protetti di trasmissione dei dati noti come HL7 e HTTPS.
<br><br>
<br><br>Minori
<br><br>Questo Sito e i Servizi del Titolare non sono destinati a minori di 18 anni e il Titolare non raccoglie intenzionalmente informazioni personali riferite ai minori. Nel caso in cui informazioni su minori fossero involontariamente registrate, il Titolare le cancellerà in modo tempestivo, su richiesta degli utenti.
<br><br>
<br><br>—— ULTERIORI POLICY E ACCORDI ——-
<br><br>Accordo di riservatezza per tutte le informazioni fornite dai nostri clienti e utenti del sito
<br><br>Il titolare del trattamento dichiara con la presente di essere consapevole che a seguito del rapporto di lavoro con i clienti e/o consulenza anche gratuita con gli utenti del sito che si rivolgono al titolare per email o per chat o per altri canali di comunicazione, potrà venire a conoscenza di dati, informazioni e notizie in genere, aventi natura riservata e si impegna a mantenere il più stretto riserbo su quanto ricevuto, nonché su qualsiasi altra notizia, confidenza e/o informazione, nel più ampio significato del termine, appresa su e/o dal cliente o utente del sito.
<br><br>
<br><br>COPYRIGHT DEI TESTI E CONTENUTI
<br><br>Grafica, layout, testi, video e codice di questo sito non possono essere replicati, neanche parzialmente, su altri siti web, mailing list, newsletter, riviste cartacee e cd rom, senza la preventiva autorizzazione del titolare del trattamento, indipendentemente da finalità di lucro.
      </p>

      <h3 class="fs16 font-o t700 lh22p m0 mb15 mt3">Modalità di esercizio dei diritti</h3>
      <p class="fs14 font-r t400 lh3 m0">
<br><br>Potrete in qualsiasi momento esercitare i diritti inviando:
<br><br>una e-mail all’indirizzo info@cerviniaresort.com
<br><br>
<br><br>Definizioni e riferimenti legali
<br><br>Dati Personali (o Dati)
<br><br>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
<br><br>
<br><br>Dati di Utilizzo
<br><br>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.
<br><br>
<br><br>Utente
<br><br>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.
<br><br>
<br><br>Interessato
<br><br>La persona fisica cui si riferiscono i Dati Personali.
<br><br>
<br><br>Responsabile del Trattamento (o Responsabile)
<br><br>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
<br><br>
<br><br>Titolare del Trattamento (o Titolare)
<br><br>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.
<br><br>
<br><br>Questa Applicazione
<br><br>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
<br><br>
<br><br>Servizio
<br><br>Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.
<br><br>
<br><br>Unione Europea (o UE)
<br><br>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.
<br><br>
<br><br>Cookie
<br><br>I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.
<br><br>
<br><br>Strumento di Tracciamento
<br><br>Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.
<br><br>
<br><br>Riferimenti legali
<br><br>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
<br><br>
<br><br>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.
<br><br>
<br><br>Ultima modifica: 22 dicembre 2020.
      </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>