import Vue from 'vue'
import App from './App.vue'
import VueParallaxJs from 'vue-parallax-js'
import { gsap, CSSPlugin } from 'gsap/all'
import VueI18n from 'vue-i18n'
import vClickOutside from 'v-click-outside'
import LottieAnimation from 'lottie-vuejs'

gsap.registerPlugin(CSSPlugin)

Vue.config.productionTip = true

Vue.use(VueParallaxJs)
Vue.use(LottieAnimation);
Vue.use(vClickOutside)
Vue.use(VueI18n)

function loadLocaleMessages () {

  const locales = require.context('@/langs', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

import '@/assets/img/animation.json'

import '@/assets/css/reset.css'
import '@/assets/css/main.scss'
import '@/assets/css/icons.scss'
import '@/assets/css/btns.scss'
import '@/assets/css/flexgrid.scss'
import '@/assets/css/shadows.scss'
import '@/assets/css/forms.scss'
import '@/assets/css/spaces.scss'
import '@/assets/css/text.scss'
import '@/assets/fontello/css/fontello.css'
import '@/assets/css/bootstrap-grid.min.css'

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
