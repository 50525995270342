<template>
  <div >
        <a :href="social === 'email' ? 'mailto:' + link.trim() : link" class="cursorp flex aic sh4 bgw pr15 brf ftd-n" :class="{p05: np,  'pr08 pv03': labelSoc}">
            <img :src="img" alt="" :class="{ic24: labelSoc}">

            <div v-if="!labelSoc" class="flex flexcol tblack ml05">
                <span v-if="title" class="fs6 t600 fupper mb05 font-o">{{ title }}</span>
                <span v-else class="fs6 t600 fupper mb05 font-o">Follow us on {{ social }}</span>

                <span class="fs10 t700 font-r">{{ description }}</span>
            </div>
        
            <div v-else class="flex flexcol tblack ml05">
                <span class="fs10 t700 font-r">{{ labelSoc }}</span>
            </div>
        </a>
  </div>
</template>

<script>
export default {
    props: ['social', 'description', 'link', 'title', 'np', 'labelSoc'],

    computed: {
        img() { return require(`@/assets/img/logos_${this.social}.svg`) }
    }

}
</script>

<style>
    .pr08 {
        padding-right: .8rem;
    }

    .pv03 {
        padding-top: .3rem;
        padding-bottom: .3rem;
        padding-left: 0.4rem;
    }
</style>