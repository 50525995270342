<template>
  <header class="twhite bglg-br" :class="{pt73: isFixedMenu && !isMobile}" ref="screen">
        <div v-if="!isMobile" class="container-fluid zi99 " ref="menu" :class="{'bglg-pc-head pos-f pos-at': isFixedMenu && !isMobile}">
            <div :class="isMobile ? 'container-fluid' : 'container'" >
                <div class="row pt2 pb2 justify-content-between align-items-center menu">
                    <div class="col-3 col-sm-2 flex aic">
                        <img class="logo" src="@/assets/img/logo.svg" alt="logo">
                    </div>


                    <div class="col-4 col-sm-4 col-md-2 flex jcfe t700 fs10 tblue">
                        <span   
                            v-for="(item, id) in lang"
                            :key="id"
                            :class="{active: id === curLang, ml1: id > 0 }"
                            @click="changeLang(id)"
                            class="ic31 sht flex aic jcc brf cursorp bglang">{{ item }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="container-fluid" ref="menu" :class="{'bglg-mob-head pos-f pos-at zi99': isFixedMenu && isMobile}">
            <div class="row pt2 pb2 justify-content-between align-items-center menu">
                <div class="col-3 col-sm-2 flex aic">
                    <img class="logo" src="@/assets/img/logo.svg" alt="logo">
                </div>

                <div class="col-4 col-sm-4 col-md-2 flex jcfe t700 fs10 tblue">
                    <span   
                        v-for="(item, id) in lang"
                        :key="id"
                        :class="{active: id === curLang, ml1: id > 0 }"
                        @click="changeLang(id)"
                        class="ic31 sht flex aic jcc brf cursorp bglang">{{ item }}</span>
                </div>
            </div>
        </div>



        <div v-if="!isMobile" class="container-fluid bgmain">

            <div class="container spaces">
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <h1 v-if="curLang === 1" ref="text" class="fs64 t700 m0 mt5 mb6 sht">{{ $t('header title') }}</h1>
                        <h1 v-else ref="text" class="fs68 t700 m0 mt5 mb6 sht">{{ $t('header title') }}</h1>

                        <p class="fs24 t500 font-r tblack m0 mb1">{{ $t('header subtitle') }}</p>
                        <span class="fs16 t400 font-r tblack m0">{{ $t('header descr') }}</span>

                        <div class="flex aic jcfs mt2 flexw wfull w50">
                            <img class="mr1 cursorp media" src="@/assets/img/pc.svg" alt="">
                            <img class="mr1 cursorp media" src="@/assets/img/play.svg" alt="">
                            <img class="cursorp media" src="@/assets/img/apple.svg" alt="">
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-6 flex jcc aifs pos-r">
                        
                        <Phones :imgs="['ski-3', 'main', 'ski-1']" :runAnim="isAnim" />
                        
                    </div> 
                </div>
            </div>
        </div>

         <div v-else class="container-fluid bglg-br" :class="{pmenu: isFixedMenu}">
                <div class="row">
                    <div class="col-md-12 col-lg-6 tal">
                        <h1 ref="textm" class="fs20 t700 m0 mt5 mb2 sht">{{ $t('header title') }}</h1>

                        <Phones :imgs="['ski-3', 'main', 'ski-1']" :runAnim="isAnim" />

                        <p class="fs24 t500 font-r tblack m0 mb05 mobile-text16 tal">{{ $t('header subtitle') }}</p>
                        <span class="fs16 t400 font-r tblack m0 mobile-text12">{{ $t('header descr') }}</span>

                        <div class="flex aic jcfs mt15 flexw wfull w50">
                            <img class="mr05 cursorp" src="@/assets/img/pc-m.svg" alt="">
                            <img class="mr05 cursorp" src="@/assets/img/play-m.svg" alt="">
                            <img class="cursorp" src="@/assets/img/apple-m.svg" alt="">
                        </div>
                    </div>

                </div>

        </div>
  </header>
</template>

<script>
import Phones from '@/components/Phones'
import { TimelineLite, Back } from 'gsap'

export default {
    components: { Phones },
    props: ['isFixedMenu', 'isMobile', 'select'],
    data() {
        return {
            curLang: 1,
            lang: ['IT', 'EN'],
            isAnim: false,
            nav: [
                {label: 'ABOUT PROJECT', link: '#about'},
                {label: 'FOOD', link: '#food'},
                {label: 'HOTELS', link: '#hotel'},
                {label: 'SKI INSTRUCTORS', link: '#ski'},
                {label: 'IN THE VILLAGE', link: '#payment'},
            ]
        }
    },

    mounted() {
        this.isAnim = this.select === 0
    },

    watch: {
        select: {
            handler(val) {
                this.isAnim = val === 0
            }
        }
    },

    methods: {
        animation() {
            const { text, textm } = this.$refs
            const timeline = new TimelineLite()

            timeline.to([text, textm], 5, {
                opacity: 1,
                ease: Back.easeInOut, // Specify an ease
            })
        },
        changeLang(id) {
            this.curLang = id
            console.log(id)
            this.$i18n.locale = this.lang[id].toLowerCase()
        }
    },
}
</script>

<style lang="scss">
    @import '@/assets/css/_vars';

    .bglg-mob-head {
        background: linear-gradient(257.78deg, #0039B9 -3.39%, #009FF9 129.41%);
    }

    .bglg-pc-head {
            background: linear-gradient(257.78deg, #0039B9 -4.79%, #009FF9 115.41%);
    }

    .pt73 {
        padding-top: 7.3rem;
    }

    .logo {
        margin: 0;
    }

    header {

        .pmenu {
            padding-top: 47px;
        }

        .fs68 {
            font-size: 6.8rem !important;
        }

        h1 {
            // opacity: 0;
        }

        .w150 {
            width: 15rem;

           
        }

        .w50 {
             img {
                width: auto !important;
                height: 4.2rem !important;
            }

            .media {
                // 
            }
        }

        .spaces {
            padding-top: 14.7rem;
            padding-bottom: 9.4rem;

            img {
                width: 100%;
            }
        }

        .wfull {
            img {
                // flex-basis: 30%;
            }
        }

        li a {
            transition: .5s;
            &:hover {
                
                opacity: .7;
            }
        }

        span.active {
            background: $blue-light-active;
        }

        @media ( max-width: 1200px ) and (min-width: 992px) {
            h1 {
                font-size: 5.8rem !important;
            }

            .spaces {
                padding-top: 9.7rem;
                padding-bottom: 6.4rem;
            }

        }

        @media ( max-width: 992px ) and (min-width: 768px) {
            button {
                margin: 0 !important; 
                width: 100% !important;
            }

            img {
            width: 100%;
        }

            li {
                a {
                    font-size: .9rem !important;
                }
            }

            h1 {
                font-size: 5.8rem !important;
            }

            .bgmain {
                background-size: cover !important;
            }

            img {
                margin-top: 2rem;
            }
                
        }

         @media ( max-width: 768px ) {
             h1 {
                text-align: center;
                font-size: 6.25vw !important;
             }

             .w50 {
                width: 100%;
                justify-content: flex-start !important;

                img {
                    &:nth-child(1),&:nth-child(2) {
                        margin-right: 0.5rem !important;
                    }
                    height: 7.7vw !important;
                    width: auto !important;
                }
            }

            .phones {
                &+p,&+p+span {
                    color: #000;
                }
            }
         }

        @media ( max-width: 768px ) and (min-width: 576px) {
            button {
                margin: 0 !important; 
                width: 100% !important;
            }

            ul {
                display: none !important;
            }

            .dn {
                display: none;
            }

            h1 {
                font-size: 5.8rem !important;
            }

            .bgmain {
                background-size: cover !important;
            }

            .ic31 {
                width: 26px;
                height: 26px;
            }

            img {
                margin-top: 2rem;
            }

            

            .sh-m {
                margin-left: -10%;
            }
                
        }

        @media ( max-width: 480px ) {
            text-align: center;
            padding-bottom: 7rem;

            .spaces {
                padding-top: 3rem;
            }

            button {
                margin: 0 !important; 
                width: 100% !important;
            }

            .dn {
                display: none;
            }

            .menu {
                padding-top: 8px;
                padding-bottom: 8px;
            }

            ul {
                display: none !important;
            }

            .ic31 {
                width: 28px;
                height: 28px;
                font-size: .8rem;
            }


            h1 {
                // font-size: 5.8rem !important;
            }

            .bgmain {
                background-size: cover !important;
            }

            img {
                margin-top: 2rem;
            }

            .dn {
                display: none;
            }

            .w50 {
                width: 100%;
            }

            .w30 {
                width: 20%;
                margin: 0 auto;
                margin-bottom: 2rem;
            }

            .sh-m {
                margin-left: -10%;
            }
                
        }

    
    }
</style>