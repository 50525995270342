<template>
  <section id="about" class="overhide" :class="{mb17: isFixed}" ref="screen">
      <div class="container">
          <div class="row">
              <div v-if="isMobile" class="col-12 col-lg-5">
                  <div class="text tblack no-parallax mb5" ref="text">
                    <button class="br4 bn bglg-o fs10 t700 fupper twhite sh1 w150 mb15 mobile-label">{{ $t('about label') }}</button>
                    <h2 class="fs24 t500 font-r m0 mb05 mobile-text18">Matterhorn Ski Resorts App</h2>
                    <p class="fs14 t400 font-o lh4 m0 mobile-text12">{{ $t('about text') }}</p>
                  </div>
              </div>

              <div class="col-12 col-lg-7 flexw flex aic"  :class="{'noflexw no-parallax': isMobile}">
                  <div class="phone-wrap" ref="phone" v-if="!isMobile">
                      <img class="phone" src="@/assets/img/main_phone.png" alt="">
                  </div>

                  <div class="phone-wrpa-m" ref="phonem" v-else>
                      <img class="phone" src="@/assets/img/main_phone.png" alt="">
                  </div>
                  

                  <div ref="items" class="mlminus155 flex flexw">
                      <img ref="line" v-if="!isMobile" class="line" src="@/assets/img/about_line.png" alt="">

                      <div class="about-items flex flexcol jcsb">
                          <div 
                            class="flex aic about-block"
                            v-for="(item, id) in about"
                            :key="id"
                            :ref="`item_${id}`"
                          > 
                              <span :class="`tab${++id}`" class="sh3 ic70 flex jcc aic brf bgw zi5 fs9 t700 fupper ls1">{{ item.label }}</span>

                              <div class="about-item bgw sh4 zi4 flex flexcol jcc pr15">
                                  <span class="fs11 t700 font-o tblack2 mb05">{{ $t(`about item ${id} t`) }}</span>
                                  <p class="m0 fs11 t400 font-r tgrey">{{ $t(`about item ${id}`) }}</p>
                              </div>
                          </div>

                      </div>

                  </div>
              </div>

              <div v-if="!isMobile" class="col-12 col-lg-5">
                  <div class="text tblack flex flexcol h100 aifs jcc" ref="text">
                    <button class="pt1 pb1 pl3 pr3 br4 bn bglg-o fs10 t700 fupper twhite sh1 w150 mb2">{{ $t('about label') }}</button>
                    <h2 class="fs24 t500 font-r m0 mb1">Matterhorn Ski Resorts App</h2>
                    <p class="fs14 t400 font-o lh4 m0">{{ $t('about text') }}</p>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import { TimelineLite, Back } from 'gsap'

export default {
    props: ['isFixed', 'select', 'isMobile'],

    data() {
        return {
            about: [
                {label: 'Dreaming', title: 'Discover Matterhorn Ski Resorts', content: 'Scopri tutte le offerte e i servizi delle Attività Turistiche Locali'},
                {label: 'Planning', title: 'Plann your Holyday', content: 'Personalizza la tua vacanza avendo a disposizione tutto il necessario'},
                {label: 'Booking', title: 'Hotels, Transfer, Ski Rent', content: 'Scegli tra tutte le offerte e prenota il tuo pacchetto personalizzato'},
                {label: 'Living', title: 'Discover Local Activities', content: 'Ski & Snowboard Instructors, Freeride, Restaurants, Apre Ski... '},
                {label: 'Sharing', title: 'Share your Expierince ', content: 'Lascia recensioni, condividi Posti, Attività Must Have o Must Do'},
                {label: 'Return', title: 'Be informed about all Offers', content: 'Ricevi Sconti e Offerte dei nostri Partner e resta sempre aggiornato'},
            ],
            isComplite: false
        }
    },

    mounted() {
        if(this.select === 2) this.animation()
    },

    watch: {
        select: {
            handler(val) {
                if(val === 1 && !this.isComplite) this.animation()
            }
        }
    },

    methods: {
        animation() {
            const { phonem, items } = this.$refs

            this.isComplite = true
            
            const timeline = new TimelineLite()
            const timeline2 = new TimelineLite()

            timeline.to(phonem, 1, {
                x: 0,// Specify an ease
            })

            timeline.to(phonem, 1, {
                x: '-100vw',// Specify an ease                
            })

            timeline2.to(items, 1, {
                x: 0,// Specify an ease
                delay: 1
            })


            // for(let i = 0; i < 6; i++) {
                timeline.to([this.$refs['item_0'], this.$refs['item_1'], this.$refs['item_2'], this.$refs['item_3'], this.$refs['item_4'], this.$refs['item_5']], 1, {
                    x: 0,
                })
            // }
        }
    }

}
</script>

<style lang="scss">
.mb17 {
    margin-bottom: 17px;
}

.noflexw {
    flex-wrap: nowrap !important;
}

.phone-wrpa-m {
    // transform: translate3d(0px, 0px, 0px) !important;

    

    // @media (max-width: 320px) {
    //     margin-left: -45.3%;    
    // }

    // @media (max-width: 340px) and (min-width: 320px) {
    //     margin-left: -42.3%; 
    // }

    // @media (max-width: 365px) and (min-width: 341px) {
    //     margin-left: -39.3%; 
    // }

    // @media (max-width: 380px) and (min-width: 366px) {
    //     margin-left: -37.3%; 
    // }

    // @media (max-width: 400px) and (min-width: 381px) {
    //     margin-left: -35.3%; 
    // }

    // @media (max-width: 420px) and (min-width: 401px) {
    //     margin-left: -33.3%; 
    // }

    // @media (max-width: 440px) and (min-width: 421px) {
    //     margin-left: -32.3%; 
    // }

    // @media (max-width: 460px) and (min-width: 441px) {
    //     margin-left: -30.3%; 
    // }

    // @media (max-width: 480px) and (min-width: 461px) {
    //     margin-left: -29.3%; 
    // }

    // @media (max-width: 500px) and (min-width: 481px) {
    //     margin-left: -28.3%; 
    // }

    img {
        width: 237px !important;
    }
}

    #about {
        padding-top: 7.9rem;

        .h100 {
            height: 100%;
        }

        .mlminus155 {
            margin-left: -15.5rem;
        }

        .about-items {
            height: 67rem;
            margin-left: -4rem;
        }

        .line {
            // opacity: 0,
        }

        .phone {
            width: 36.9rem;
            height: auto;

            // transform: translateX(-100px);
            margin-left: -8.4rem;

            // opacity: 0;
        }

        .text {
            // transform: translateX(100px);
            // opacity: 0;
        }

        .about-item {
            width: 23.2rem;
            height: 6.3rem;
            padding-left: 45px;
            margin-left: -3.5rem;
            border-radius: 0px 40px 40px 0px;
        }

        .about-block {
            // transform: translateX(100px);
            // opacity: 0;

            &:nth-child(5), &:nth-child(2) {
                margin-left: -5rem;
            }
            &:nth-child(1) {
                margin-left: -15.5rem;
            }
            &:nth-child(6) {
                margin-left: -14rem;
            }
        }

        @media ( max-width: 1200px ) and (min-width: 768px) {
            .about-item {
                width: 22rem;
            }

            .mlminus155 {
                margin-left: -13.5rem !important;

                img {
                    width: 21.5rem;
                }
            }

            .phone {
                width: 31.9rem !important;
                height: auto;
            }

            .about-block {
                &:nth-child(5), &:nth-child(2) {
                    margin-left: -5rem;
                }
                &:nth-child(1) {
                    margin-left: -12.5rem;
                }

                &:nth-child(6) {
                    margin-left: -14rem;
                }
            }
        }

        @media ( max-width: 768px ) {
            padding-top: 8rem;
            padding-bottom: 8rem;
            
            img {
                display: none;
            }

            .phone-wrpa-m {
                // display: none;
                width: 100%;
                transform: translateX(110vw);
                position: absolute;

                img {
                    width: 101.8vw !important;
                    margin-left: -13vw;
                }
            }

            .phone {
                display: block;
                width: 80%;
                margin: 0 auto;
            }

            .mlminus155 {
                margin-left: 0 !important;
                width: 100%;
                // display: none;
                transform: translateX(100vw);
                justify-content: flex-end;
            }

            .ic70 {
                width: 6.9rem;
                height: 6.9rem;

                font-size: 0.9rem;
            }
           

            .about-item {
                width: 92%;
                height: 6.7rem;
                padding-right: 2rem;
                padding-left: 5rem;
                position: absolute;
                right: 0;
                

                p,span {
                    font-size: 1.4rem;
                }
            }

            .about-block {
                margin-left: 0 !important;
                margin-bottom: 1.1rem;
                width: 100%;
                position: relative;
                

                &:nth-child(1) { transform: translateX(0px); }
                &:nth-child(2) { transform: translateX(20px); }
                &:nth-child(3) { transform: translateX(40px); }
                &:nth-child(4) { transform: translateX(60px); }
                &:nth-child(5) { transform: translateX(80px); }
                &:nth-child(6) { transform: translateX(100px); }
            }

            .about-items {
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                // margin: 0 auto;

                height: auto;
            }
        }

        // @media ( max-width: 420px ) {
        //     .ic70 {
        //         width: 6.9rem;
        //         height: 6.9rem;
        //     }

        //     .about-item {
        //         height: 6.7rem;    

        //         p,span {
        //             font-size: 1.4rem;
        //         }
        //     }
        // }

        @media ( max-width: 350px ) {
            .ic70 {
                width: 6.5rem;
                height: 6.5rem;

                font-size: 0.8rem;
            }

            .about-item {
                height: 6.3rem;    

                p,span {
                    font-size: 1.2rem;
                }
            }
        }
    }
    
</style>