<template>
  <section :id="data.id" :class="isMobile && (id%2 !== 0) ? 'bg-mob-screen' : ''" class="data overhide" ref="screen">
      <div class="container">
          <div class="row justify-content-between align-items-center">
              <div v-if="!isMobile" class="col-12 col-lg-6">
                  <Phones :imgs="data.imgs" :runAnim="isAnim" />
              </div>

              <div class="col-12 col-lg-5 text" :class="{'no-parallax': isMobile}"  ref="text">
                  <button class="pt1 pb1 pl3 pr3 br4 bn fs10 t700 fupper twhite sh1 w150 mb2 mobile-label" :class="`bglg-${data.bg}`">{{data.label}}</button>
                  <h2 class="fs24 t500 font-r m0 mb1 mobile-text18" :class="{'mb05' : isMobile}">{{$t(data.title)}}</h2>
                  <p class="fs16 t400 font-o lh4 m0 mb5 mobile-text12" :class="{'mb3' : isMobile}">{{$t(data.content)}}</p>

                  <div class="flex flexw items mb1">
                      <span v-for="(item, id) in data.items" :key="id" :class="{ml07: id > 0}" class="p15 br4 twhite fs12 t700 font-o lh3 flex aic jcc">
                          {{item}}
                      </span>
                  </div>
              </div>

               <div v-if="isMobile" class="col-12 col-lg-6">
                  <Phones :imgs="data.imgs" :runAnim="isAnim" class="phone wfulls"/>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import Phones from '@/components/Phones'
import { TimelineLite, Back } from 'gsap'

export default {
    props: ['data', 'id', 'isMobile', 'select'],
    components: { Phones },

    data() {
        return {
            isAnim: false,
            items: ['Hot Offers', 'Last-Minute', 'Special offer']
        }
    },

    computed: {
        img() { return require(`@/assets/img/${this.data.img}.png`) }
    },

    mounted() {
        if(this.select === this.data.anim) this.isAnim = true
    },

    watch: {
        select: {
            handler(val) {
                if(val === this.data.anim) this.isAnim = true
            }
        }
    },

    methods: {
        animation() {
            const { text } = this.$refs
            const timeline = new TimelineLite()

            timeline.to(text, 3, {
                opacity: 1,
                x: 0,
                ease: Back.easeInOut, // Specify an ease
            })
        }
    }
}
</script>

<style lang="scss">
.bg-mob-screen {
    background: #FAFAFB;
}
    .data {
        padding-top: 13rem;
        padding-bottom: 9.7rem;

        .phones {
            margin-left: -5rem;
        }


        .ml07 {
            margin-left: 1.2rem;
        }

        .text {
            // height: 100%;
        }

        img {
            // margin-left: -8.4rem;
        }
        
        span {
            height: 6rem;
            flex: 1;

            &:nth-child(2) { background: #FA8722; }
            &:nth-child(1) { background: #41B04C; }
            &:nth-child(3) { background: #4539D6; }
            
        }

        @media (max-width: 992px) {
            img {
                width: 100%;
                margin-left: 0;
            }
        }

        @media (max-width: 768px) {
            padding-top: 3rem;
            padding-bottom: 5rem;

            .phones {
                margin-left: 0;
            }

            .phone {
                margin-left: -5.3vw;
            }

            .ml07 {
                margin-left: 0.7rem;
            }

            .items {
                
                span {
                    font-size: 1.2rem;
                    height: auto;
                    min-height: 4rem ;
                    padding: 0.5rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
</style>