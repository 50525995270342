<template>
  <footer :id="isId ? 'footer' : ''" :class="isId ? 'bglg-b' : 'bgt'" class=" twhite" ref="footer">
      <div class="container">
          <div class="row">
              <div class="col-12 col-md-6 flex jcsb aic mob-links">
                  <span class="fs12 t400 font-r lh18p m0">© 2020 CerviniaResort</span>
                  <a @click="$emit('open-policy')" class="fs12 t400 font-r funderline lh18p m0 cursorp">Privacy Policy</a>
                  <a @click="$emit('open-cookie')" class="fs12 t400 font-r funderline lh18p m0 cursorp">Cookie Policy</a>
              </div>
              
          </div>
      </div>

      
  </footer>
</template>

<script>
import Kick from '@/components/Kick'
import Social from '@/components/Social'
import BenefitItem from '@/components/BenefitItem'
import axios from 'axios'

export default {
    components: { Kick, Social, BenefitItem },

    props: {
        isMobile: {
            type: Boolean,
            default: false
        },

        isId: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {

        }
    },

    methods: {

    }
}
</script>

<style lang="scss">
@import '@/assets/css/_vars';

.not-valid {
    border: 2px solid red;
    outline: 1px solid red;
}

footer {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media ( max-width: 992px ) {
        .w65 {
            margin: 0 auto;
            margin-top: 3rem;
            margin-bottom: 2rem;
        }

        .m0auto {
            margin: 0 auto;
        }

        .fc {
            justify-content: center;
        }

        .social {
            margin: 0 auto;
            margin-top: 2rem;
            text-align: center;
        }
    }

    @media ( max-width: 768px ) {
        padding-top: 1rem;
        padding-bottom: 5rem;
        .mob {
                margin-bottom: 1rem;
                display: block;
        }

        .mobile-text-block {
            

            .mob1 {
                margin-bottom: 1.5rem;
            }

            li {
                margin-bottom: 1rem;
                line-height: 140%;
            }
        }


        .adt {
            flex-wrap: wrap;
            justify-content: flex-start;

            span {
                position: relative;
                margin-top: 2rem;
            }
        }

        .w65 {
            width: 100%;
            margin-top: 4rem;
            padding: 2rem 1.4rem;
            h3 {
                font-size: 1.4rem;
                margin-bottom: 1rem;
            }

            .kick {
                width: 80vw;
                .logo {
                    width: 5.7rem;
                }

                span {
                    font-size: 7.46vw;
                    margin-bottom: 0.8rem;
                    font-weight: 600;
                }

                .title {
                    width: 59.065vw;
                }
            }

            button {
                width: 100%;
                padding: 1.2em 0rem ;
            }

            
        }

        .form {
            padding: 0 !important;
            margin-bottom: 4rem !important;

            input[type="color"],
            input[type="date"],
            input[type="datetime"],
            input[type="datetime-local"],
            input[type="email"],
            input[type="month"],
            input[type="number"],
            input[type="password"],
            input[type="search"],
            input[type="tel"],
            input[type="text"],
            input[type="time"],
            input[type="url"],
            input[type="week"],
            select:focus,
            textarea {
                font-size: 16px !important;
            }

            h2 {
                margin: 0 !important;
                margin-bottom: 2rem !important; 
                font-size: 1.6rem !important;
                font-weight: 500 !important;
            }

            input, .pos-r {
                margin-bottom: 1.5rem !important;
                font-size: 1.2rem !important;
            }

            select {
                font-size: 1.6rem !important;
            }

            .mt3 {
                margin-top: 0;
            }

            label {
                font-size: 1.2rem;
                margin-bottom: 3rem;
                // margin-right: 0 !important;
                flex: 1;
            }

            #check {
                align-self: flex-start;
            }
        }

        .mba2 {
            margin-bottom: 2rem !important;
        }

        .mob-links {
            span, a {
                font-size: 1.2rem;
                line-height: 1.5rem;
            }
        }

        .social {
            text-align: left;
            margin: 0;
            width: 100%;

            .cursorp {
                margin: 0;
                padding: 0.3rem;
                padding-right: 0.8rem;
                
                margin-bottom: 1.5rem;
            }

            .flexw {
                justify-content: center;
            }

            .socials {
                justify-content: flex-start !important;

                .brf {
                    margin-bottom: 0 !important;
                }
            }
        }

        hr.mb3 {
            margin-bottom: 2rem;
        }

        .mobile {

            &-text {
                .mob {
                    margin-bottom: 0.5rem;
                }

                
                    li {
                        margin-bottom: 1rem;
                        line-height: 140%;
                    }
                
            }

            .item {
                width: 100%;
                height: 8rem;
                padding-top: 0;
                padding-bottom: 0;
                // justify-content: center;
                align-items: center !important;
            }
        }
    }
}

</style>