<template>
  <div class="flex w249 aic jcc pt15 pb15 pl2 pr15 b-bene br4">
      <div class="flex aifs">
            <i :class="`fs24 lh24p mr1 demo-icon icon-${icon}`"></i>
            <p class="t300 lh3 m0 font-o" :class="isMobile ? 'font-o fs14' : 'font-r fs12'">{{ $t(`footer item ${id + 1}`) }}</p>
      </div>
  </div>
</template>

<script>
export default {
    props: ['content', 'icon', 'id', 'isMobile']
}
</script>

<style>
 .w249 {
     width: 24.9rem;
 }

 i {
     width: 24px;
 }
</style>