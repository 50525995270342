<template>
  <div class="flex aic kick">
      <img class="logo" src="@/assets/img/kick.svg" alt="">

      <div class="flex flexcol aifs jcc ml15 mtminus05">
          <span class="t300 fs16 lh22p font-o" :class="{'twhite': color}">SUPPORT US ON</span>
          <img class="title" :class="{h11 : isMob}" src="@/assets/img/kick_text.svg" alt="">
          <!-- <img v-else class="title" src="@/assets/img/kick-text-m.svg" alt=""> -->
      </div>
  </div>
</template>

<script>
export default {
  props: ['color', 'isMob'],

  mounter() {
    console.log(this.isMob)
  }
}
</script>

<style lang="scss">
.kick {
  .title {
    height: 1.9rem;
  }

  .h11 {
    height: 1.1rem !important;
  }

  @media ( max-width: 768px ) { 
    .title {
      height: 7vw;
    }
  }

  @media ( max-width: 480px ) { 
    width: 80%;

    span {
      font-size: 1.5rem;
    }
    .logo {
      width: 40px;
    }

    .title {
      width: 80%;
    }
  }
}
</style>